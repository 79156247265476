<template lang="">
  <div>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
          <TitlePlus
            :title="
              $t(
                'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.headline'
              )
            "
            :hide-plus="true"
          />
          <div class="md:flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 my-1 md:items-center md:flex-row px-5">
        <SummaryCard
          :title="
            $t(
              'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.summary.total'
            )
          "
          :value="indexMetaData.count.total"
          variant="gray"
        />
      </div>

      <FSTable
        :fst-id="`marketplaceRevenueIndex`"
        :searchEnabled="false"
        :headers="getTableHeaders"
        :endpoint="getEndpoint"
        :qso="getEndpointOptions"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem :text="item.id" :truncate="-5" />
                <FSTableRowItem
                  :text="`${item.buyer.first_name} ${item.buyer.last_name}`"
                  :to="{
                    name: 'ViewBuyerProfile',
                    params: { id: item.buyer.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem
                  :text="item.fleet.fleet.name"
                  :to="{
                    name: 'ViewFleetProfile',
                    params: { id: item.fleet.fleet.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem :text="item.issued_at" :date="true" />
                <FSTableRowItem :text="item.paid_at" :date="true" />
                <FSTableRowItem :text="getMonthYear(item.billing_period)" />
                <FSTableRowItem :text="item.vehicle_quantity || '--'" />
                <FSTableRowItem
                  :text="
                    `${item.fleet.fleet.country.currency_symbol} ${item.tax}`
                  "
                />
                <FSTableRowItem
                  :text="
                    `${item.fleet.fleet.country.currency_symbol} ${item.total_amount}`
                  "
                />
                <FSTableRowItem :text="item.description || '--'" />
                <FSTableRowItem>
                  <XStatus
                    :text="getItemStatus(item.status)"
                    :variant="getItemVariant(item.status)"
                    profile="payment"
                  />
                </FSTableRowItem>
              </FSTableRow>
            </template>

            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="`${item.buyer.first_name} ${item.buyer.last_name}`"
                  :to="{
                    name: 'ViewBuyerProfile',
                    params: { id: item.buyer.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem
                  :text="
                    `${item.fleet.fleet.country.currency_symbol} ${item.total_amount}`
                  "
                />
                <FSTableRowItem>
                  <XStatus
                    :text="getItemStatus(item.status)"
                    :variant="getItemVariant(item.status)"
                    profile="payment"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.invoiceId'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      #{{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.fleet'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <router-link
                        v-if="item.fleet"
                        class="font-bold text-blue-600 capitalize"
                        :to="{
                          name: 'ViewFleetProfile',
                          params: {
                            id: item.fleet.fleet.id,
                            redirectPath: path,
                          },
                        }"
                      >
                        {{ item.fleet.fleet.name }}
                      </router-link>
                      <span v-else>--</span>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.issuedAt'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.issued_at,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.paidAt'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.paid_at,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.billingPeriod'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getMonthYear(item.billing_period) }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.vehicleQuantity'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.vehicle_quantity || '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.tax'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        `${item.fleet.fleet.country.currency_symbol} ${item.tax}`
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.description'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.description || '--' }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </div>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { getFormattedDateTime } from '@/utils/datetime'
import XStatus from '@/components/badge/XStatus'
export default {
  name: 'ViewMarketplaceRevenues',
  components: {
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    DateRangePicker,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
  },
  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      path: '',
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.invoiceId'
          ),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.invoiceFor'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.fleet'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.issuedAt'
          ),
          width: '12%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.paidAt'
          ),
          width: '12%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.billingPeriod'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.vehicleQuantity'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.tax'
          ),
          width: '5%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.amount'
          ),
          width: '7%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.description'
          ),
          width: '13%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.status'
          ),
          width: '10%',
          sort: 'null',
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.invoiceFor'
          ),
          width: '40%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.amount'
          ),
          width: '20%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.status'
          ),
          width: '30%',
          sort: 'null',
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    id() {
      return this.$route.params.id
    },
    getEndpoint() {
      return MarketplaceConfig.api.monthlyRevenueIndex(this.id)
    },

    getEndpointOptions() {
      return {
        prepend: '&',
        append: '',
      }
    },
  },
  async created() {
    this.path = this.$route.path
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getItemVariant(data) {
      if (data === 'paid') return 'green'
      if (data === 'unpaid') return 'gray'
      return 'red'
    },
    getItemStatus(data) {
      if (data === 'paid') return 'Paid'
      if (data === 'unpaid') return 'Unpaid'
      return data
    },
    getMonthYear(date) {
      const splittedDate = date.split('/')
      const zuluDate = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}T00:00:00`
      return this.getFormattedDateTime(zuluDate, 'MMM, YYYY')
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
