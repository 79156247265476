export const MarketplaceConfig = {
  api: {
    // get
    index: () => '/dashboard/marketplace/marketplace-fleet-list/',
    // post
    create: () => '/dashboard/marketplace/marketplace-fleet/',
    // get
    details: (marketplaceId) =>
      `/dashboard/marketplace/marketplace-fleet/${marketplaceId}/`,
    // patch
    update: (marketplaceId) =>
      `/dashboard/marketplace/marketplace-fleet/${marketplaceId}/`,
    // get
    purchaseIndex: (marketplaceId) =>
      `/dashboard/marketplace/purchases/?marketplace_fleet=${marketplaceId}`,

    monthlyRevenueIndex: (marketplaceId) =>
      `/dashboard/marketplace/monthly-revenues/?marketplace_fleet=${marketplaceId}`,
    marketplaceBuyerList: (marketplaceId) =>
      `/dashboard/marketplace/buyer-list/?marketplace_fleet=${marketplaceId}`,
    marketplaceAllBuyerList: () => `/dashboard/marketplace/buyer-list/`,
    //get
    marketplaceBuyerProfile: (buyerId) =>
      `/dashboard/marketplace/marketplace-buyer/${buyerId}/profile/`,
    //get
    marketplaceBuyerOrder: (buyerId) =>
      `/dashboard/marketplace/marketplace-buyer/${buyerId}/orders/`,
    //delete
    marketplaceDeleteBuyerOrder: (orderId) =>
      `/dashboard/marketplace/buyer-order/${orderId}/`,
    marketplaceAllPayoutIndex: () =>
      `/dashboard/marketplace/marketplace-payout/`,
    marketplacePlaceOrder: () => `/dashboard/marketplace/place-order/`,
    sendPurchaseInvoice: () => `/dashboard/marketplace/send-invoice/`,
    transferMoneyOfFunds: () => `/dashboard/marketplace/transfer-money/`,
    getOrderEmailTemplate: (orderId) =>
      `/dashboard/marketplace/payment-invoices/${orderId}/`,
  },
  events: {
    name: 'marketplace',
    // refresh-index-data
    refresh: `rid-marketplace`,
    // slideover-right
    sorId: 'marketplace',
    sorOpen: 'sor-open-marketplace',
    sorClose: 'sor-close-marketplace',
    sorToggle: 'sor-toggle-marketplace',
    // editing-data
    editingData: 'edit-marketplace-data',
    // viewing-data
    viewingData: 'view-marketplace-data',
  },
}
